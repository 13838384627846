import React from "react"

export default props => (
  <article className={`post-card-diretorio`} >
        <div class="menu" >
          <div className="menu__item">
            <a className="menu__item-link" href={`/${props.node.slug}`}>{props.node.title || props.node.slug}</a>
            
            <div className="marquee">
              <div className="marquee__inner" aria-hidden="true">
               
              
                <span>{props.node.description}</span>
                
                

                <span>{props.node.description}</span>

                
                <span>{props.node.description}</span>
              
              </div>
            </div>
          </div>
        </div>
      </article>
)
