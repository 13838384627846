import React from "react"
import { graphql, StaticQuery } from "gatsby"


import Layout from "../components/layout"
import SEO from "../components/seo"
import PostCardDiretorio from "../components/postCardDiretorio"

// import "../utils/global.scss"
import "../utils/normalize.css"
import "../utils/css/screen.css"
//TODO: switch to staticQuery, get rid of comments, remove unnecessary components, export as draft template
const CreatorsCat = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title
  const postsDiretorio = data.allContentfulDiretorioDeArtista.edges
  const categorias = data.allContentfulContentType.edges
  let postCounter = 0

  return (
    <Layout title={siteTitle} >
      <SEO
        title="Diretorio De Artistas"
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />
      
       <div className="row" style={{backgroundColor: "#fff"}}>
        <div className="col-12 box-sections">
        <div className="post-content" 
            style={{
              paddingTop: "50px", 
              paddingBottom: "0px",
              textAlign: "center"
            }}
           >

        <h1 className="post-content-header" style={{marginTop: "150px", height: "200px",}}>Diretorio De Artistas</h1>
        </div>
        </div>
        </div>
      <div className="row" style={{marginTop: '0px', paddingBottom: "100px"}}>
        
          {postsDiretorio.map(({ node }) => {
            postCounter++
            return (
              <PostCardDiretorio
                key={node.slug}
                count={postCounter}
                node={node}
                postClass={`post`}
              />
            )
          })}
          </div>
      

     
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allContentfulDiretorioDeArtista(sort: {order: ASC, fields: title}){
      edges{
        node{
          title
          description
          publishedDate
          slug
          image{
            fluid(maxWidth: 2048, quality: 100){
              src
            }
          }
        }
      }
    }
    allContentfulContentType {
    edges {
      node {
        name
        displayField
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <CreatorsCat location={props.location} props data={data} {...props} />
    )}
  />
)
